* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: auto;
  overflow-y: auto;
  margin-left: 1400px;
}

a {
  text-decoration: none;
  color: #c9ab00;
}

a:hover {
  text-decoration: underline;
}

.link {
  text-decoration: none;
  color: #c9ab00;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}
 
::-webkit-scrollbar-thumb {
  background:rgba(94, 93, 93, 0.2); 
  border-radius: 4px;
}

.App {
  width: 1000px;
  margin: 0 auto;
}

.app-header {
  position: relative;
  height: 767px;
}

.line {
  top: 0px;
  left: 390px;
  position: absolute;
  z-index: 100;
}

.image-me {
  width: 345px;
  top: 272px;
  left: 248px;
  position: absolute;
  z-index: 10;
}

.app-header__text {
  position: relative;
  top: 20px;
  left: 0;
  width: 410px;
}

.app-header__text h3 {
  margin-top: 8px;
  font-size: 26px;
  font-weight: 800;
}

/*--------------- Line-Animation -----------------*/

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.path {
  stroke-dasharray: 1889.35;
  stroke-dashoffset: 1889.35;
  animation: dash 8s linear alternate forwards ;
}

.path-cv {
  stroke-dasharray: 7443.8564453125;
  stroke-dashoffset: 7443.8564453125;
  animation: dash 20s linear 8s alternate forwards ;
}

.path-thesis {
  stroke-dasharray: 2888.8955078125;
  stroke-dashoffset: 2888.8955078125;
  animation: dash 4s linear 28s alternate forwards;
}

.path-moosic {
  stroke-dasharray: 5443.73876953125;
  stroke-dashoffset: 5443.73876953125;
  animation: dash 12s linear 0s alternate forwards ;
}

.path-komma-rum {
  stroke-dasharray: 6452.109375;
  stroke-dashoffset: 6452.109375;
  animation: dash 14s linear 0s alternate forwards ;
}

.path-im-rahmen-app {
  stroke-dasharray: 8855.9921875;
  stroke-dashoffset: 8855.9921875;
  animation: dash 16s linear 0s alternate forwards ;
}

.path-im-rahmen-web {
  stroke-dasharray: 6064.33984375;
  stroke-dashoffset: 6064.33984375;
  animation: dash 12s linear 0s alternate forwards ;
}

/*--------------- CV -----------------*/

.cv {
  position: relative;
  height: 600px;
}

.line-cv {
  position: absolute;
  top: 0;
  left: 101px;
}

.cv h2 {
  position: relative;
  top: 120px;
  left: 600px;
}

.cv table {
  position: relative;
  top: 245px;
  left: 470px;
}

.cv td {
  vertical-align: top;
  padding: 9px 10px;
}

.cv .table-left {
  font-weight: 500;
  font-size: 18px;
  text-align: right;
}

.cv .table-right {
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  width: 320px;
}

/*--------------- Skills -----------------*/

.skills {
  position: relative;
  height: 900px;
}

.skills h2 {
  position: relative;
  top: 540px;
  left: 580px;
}

.skills h4 {
  position: absolute;
  font-weight: 300;
  text-align: center;
}

.skills .small {
  font-size: 14px;
}

.skills .medium {
  font-size: 18px;
}

.skills .big {
  font-size: 22px;
}

.skill1 {
  top: 890px;
  left: 560px;
}

.skill2 {
  top: 630px;
  left: 1060px;
}

.skill3 {
  top: 660px;
  left: 790px;
}

.skill4 {
  top: 690px;
  left: 500px;
}

.skill5 {
  top: 720px;
  left: 910px;
}

.skill6 {
  top: 470px;
  left: 120px;
}

.skill7 {
  top: 530px;
  left: 340px;
}

.skill8 {
  top: 630px;
  left: 180px;
}

.skill9 {
  top: 730px;
  left: 300px;
}

.skill10 {
  top: 330px;
  left: 340px;
}

.skill11 {
  top: 300px;
  left: 550px;
}

.skill12 {
  top: 420px;
  left: 750px;
}

.skill13 {
  top: 370px;
  left: 880px;
}

.skill14 {
  top: 550px;
  left: 940px;
}

.skill15 {  
  top: 430px;
  left: 220px;
}

.skill16 {
  top: 790px;
  left: 690px;
}

/*--------------- Projects -----------------*/

.projects {
  position: relative;
  height: 1250px;
}

.projects h2 {
  position: relative;
  top: 125px;
  left: 140px;
}

.projects__moosic {
  width: 350px;
  position: absolute;
  top: 0;
  left: 0;
}

.projects__moosic-container {
  position: absolute;
  width: 350px;
  height: 218.75px;
  top: 255px;
  left: 240px;
  z-index: 1000;
}

.projects__moosic-container:hover .overlay {
  opacity: 1;
}

.projects__im-rahmen {
  width: 180px;
  position: absolute;
  top: 0;
  left: 0;
}

.projects__im-rahmen-container {
  position: absolute;
  width: 180px;
  height: 320px;
  top: 380px;
  left: 630px;
  z-index: 1000;
}

.projects__im-rahmen-container:hover .overlay {
  opacity: 1;
}

.projects__im-rahmen-web {
  width: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

.projects__im-rahmen-web-container {
  position: absolute;
  width: 500px;
  height: 312.5px;
  top: 525px;
  left: 60px;
  z-index: 1000;
}

.projects__im-rahmen-web-container:hover .overlay {
  opacity: 1;
}

.projects__komma-rum {
  width: 600px;
  position: absolute;
  top: 0;
  left: 0;
}

.projects__komma-rum-container {
  position: absolute;
  width: 600px;
  height: 281.85px;
  top: 875px;
  left: 200px;
  z-index: 1000;
}

.projects__komma-rum-container:hover .overlay {
  opacity: 1;
}

.container {
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: .5s ease;
  background-color: #00000085;
}

.overlay-text {
  color: white;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.overlay-text span {
  font-size: 20px;
  margin-top: 15px;
  white-space: nowrap;
  display: block;
}

/*--------------- Moosic -----------------*/

.moosic {
  width: 1000px;
  padding-right: 100px;
  position: absolute;
  top: -165px;
  left: calc(50% + 700px);
}

.moosic h2 {
  position: unset;
}

.line-moosic {
  position: absolute;
  left: -610px;
  top: -70px;
  z-index: 10;
}

.moosic p {
  margin: 22px 0 34px 0;
  line-height: 24px;
  width: 640px;
  position: relative;
  z-index: 100;
}

.moosic-web-images img{
  margin-bottom: 20px;
  width: 340px;
}

.moosic-web-images {
  width: 340px;
  position: absolute;
  top: 0;
  right: 100px;
}

.moosic-app-images img{
  height: 360px;
  margin-right: 20px;
}

/*--------------- Komma Rum -----------------*/

.komma-rum {
  width: 1000px;
  height: 876px;
  padding-right: 100px;
  position: absolute;
  top: 1000px;
  left: calc(50% + 700px);
}

.komma-rum h2 {
  position: unset;
}

.line-komma-rum {
  position: absolute;
  left: -1094px;
  top: -84px;
  margin-bottom: 40px;
  z-index: 10;
}

.komma-rum p {
  margin: 22px 0 34px 0;
  line-height: 24px;
  width: 820px;
  position: relative;
  z-index: 100;
}

.komma-rum-images img{
  height: 300px;
  margin: 0 20px 20px 0;
}

/*--------------- Im Rahmen - App -----------------*/

.im-rahmen-app {
  width: 880px;
  position: absolute;
  top: -800px;
  left: calc(50% - 1800px);
}

.im-rahmen-app h2 {
  position: unset;
}

.line-im-rahmen-app {
  position: absolute;
  left: -105px;
  top: -100px;
  margin-bottom: 40px;
  z-index: 10;
}

.im-rahmen-app p {
  margin: 22px 0 34px 0;
  width: 860px;
  line-height: 24px;
  position: relative;
  z-index: 100;
}

.im-rahmen-app img{
  width: 200px;
  margin: 0 20px 20px 0;
}

#im-rahmen-app-video {
  width: 860px;
}

/*--------------- Im Rahmen - Webeditor -----------------*/

.im-rahmen-web {
  width: 880px;
  position: absolute;
  top: 800px;
  left: calc(50% - 1600px);
}

.im-rahmen-web h2 {
  position: unset;
}

.line-im-rahmen-web {
  position: absolute;
  left: -336px;
  top: -110px;
  margin-bottom: 40px;
  z-index: 10;
}

.im-rahmen-web p {
  margin: 22px 0 34px 0;
  width: 860px;
  line-height: 24px;
  position: relative;
  z-index: 100;
}

#im-rahmen-web-video {
  width: 860px;
}

/*--------------- Thesis -----------------*/

.thesis {
  width: 880px;
  position: relative;
  top: 100px;
  left: 260px;
}

.thesis h2 {
  position: relative;
  z-index: 100;
}

.line-thesis {
  position: absolute;
  left: -86px;
  top: -76px;
  z-index: 10;
}

.thesis p {
  margin: 12px 0 24px 0;
  line-height: 24px;
  position: relative;
  z-index: 100;
}

.thesis-headline {
  width: 350px;
  transition: color 0.5s;
}

/*
.thesis-headline:hover {
  color:  #c9ab00;
}
*/

.thesis-summary {
  width: 700px;
  position: relative;
  top: 0px;
  left: 0px;
}

/*--------------- Contact -----------------*/

.contact {
  width: 350px;
  position: relative;
  top: 420px;
  left: -150px;
}

.contact h3 {
  text-align: right;
  font-weight: 300;
}
/*--------------- Impressum -----------------*/

.impressum {
  position: fixed;
  height: 24px;
  width:300px;
  right:0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0px 10px 20px 30px rgba(255,255,255,0.7);
  -moz-box-shadow: 0px 10px 20px 30px rgba(255,255,255,0.7);
  box-shadow: 0px 10px 20px 30px rgba(255,255,255,0.7);
  z-index:10000;
}

.impressum span {
  font-size: 12px;
}

.line-impressum {
  position: fixed;
  right:0;
  bottom: 25px;
  z-index: 2000;
}

.background-impressum {
  position: fixed;
  right: 160px;
  bottom: 90px;
  z-index: 1800;
  opacity: 1;
}

.impressum-content {
  position: fixed;
  right:222px;
  bottom: 178px;
  overflow-y: scroll;
  width: 640px;
  height: 300px;
  z-index: 3000;
}

.impressum-close {
  position: fixed;
  right:172px;
  bottom: 524px;
  font-size: 24px !important;
}

.impressum-close:hover {
  font-weight: 700;
}

.impressum-content h2 {
  margin-top: 20px;
}

.impressum-content p {
  font-size: 12px;
}

/* width */
.impressum-content::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.impressum-content::-webkit-scrollbar-track {
  border-radius: 4px;
}
 
/* Handle */
.impressum-content::-webkit-scrollbar-thumb {
  background:rgba(255, 238, 0, 0.2); 
  border-radius: 4px;
}

/*--------------- general -----------------*/

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.upside-down {
  -moz-transform:rotate(-180deg); /* Firefox */
  -webkit-transform:rotate(-180deg); /* Webkit */        
  -ms-transform:rotate(-180deg); /* IE */
  -o-transform:rotate(-180deg); /* Opera */
  transform:rotate(-180deg); /* future */
}

.hidden {
  display: none;
}

.visible {
  display: block;
}